<template>
  <div id="companiesContainer">

    <vs-breadcrumb class="mb-5">
      <li>
        <router-link to="/">Domov</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li aria-disabled="page" class="disabled-link">Donacije<span class="vs-breadcrum--separator">/</span></li>
      <li>
        <router-link :to="{name: 'PrivateDonations'}">Donacije zasebnikov</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">
        <span v-if="$route.params.donationId">Urejanje donacije zasebnika</span>
        <span v-if="!$route.params.donationId">Dodajanje donacije zasebnika</span>
      </li>
    </vs-breadcrumb>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">

        <div class="grid grid-cols-1 mb-10">
          <div>
            <h1>
              <span v-if="$route.params.donationId">Urejanje donacije zasebnika</span>
              <span v-if="!$route.params.donationId">Dodajanje donacije zasebnika</span>
            </h1>
          </div>
        </div>
    <div class="grid grid-cols-1">
      <div>
        <label class="vs-input--label">Datum donacije</label>
        <datepicker placeholder="Datum donacije" :language="slSI" :format="customDateFormatter"
                    :monday-first="true"
                    v-model="donationData.donationDate"></datepicker>
      </div>
    </div>

    <div class="grid grid-cols-1 mt-5" v-if="$globalFunctions.isAdmin()">
      <div>
        <label class="vs-input--label">Donacija za enoto</label>
        <v-select id="unit" :options="unitsData" class="" @input="onUnitChange" style="min-width: 300px"
                  v-model="unitId" placeholder="Donacija za enoto"
                  :reduce="item => item.id"
                  v-if="unitsData"
                  :clearable="false"
                  label="name">

          <template v-slot:option="option">
            <vs-list-item
              :title="option.name"
              :subtitle="option.coveredMunicipalities">
              <template slot="avatar" v-if="option.name">
                <vs-avatar size="large"
                           :text="option && option.name && option.name.toString().substr(0,1) + (option.name.toString().match(/\d/g) ? option.name.toString().match(/\d/g).join('') : '')"
                           class="mr-5"/>
              </template>
            </vs-list-item>
          </template>

          <template v-slot:selected-option="option">
            <vs-list-item
              :title="option.name"
              :subtitle="option.coveredMunicipalities">
              <template slot="avatar" v-if="option.name">
                <vs-avatar size="large"
                           :text="option && option.name && option.name.toString().substr(0,1) + (option.name.toString().match(/\d/g) ? option.name.toString().match(/\d/g).join('') : '')"
                           class="mr-5"/>
              </template>
            </vs-list-item>
          </template>

          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
            </template>
            <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
          </template>
        </v-select>
      </div>
    </div>


    <div class="grid grid-cols-1 mt-5">
      <div>
        <vs-input class="w-full" label="Ime donatorja" type="text"
                  v-model="donationData.donorName" />
      </div>
    </div>

    <div class="grid grid-cols-1 mt-5">
      <div>
        <vs-input class="w-full" label="Naslov donatorja" type="text"
                  v-model="donationData.donorAddress" />
      </div>
    </div>

    <div class="grid grid-cols-1 mt-5">
      <div>
        <vs-input class="w-full" label="Znesek donacije" type="number"
                  v-model="donationData.donationAmount"  />
      </div>
    </div>

    <div class="grid grid-cols-1 mt-5" v-if="$globalFunctions.isAdmin()">
      <div>
        <vs-input class="w-full" label="Referenca" type="text"
                  v-model="donationData.donationReference" />
      </div>
    </div>

        <div class="flex mt-10 mb-20">
          <div class="w-1/2">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
              <vs-button type="border" icon="cancel" @click="$router.go(-1)" class="mt-2">Prekliči</vs-button>
            </vs-col>
          </div>
          <div class="w-1/2">
            <div class="flex">
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" v-if="donationData.id">
                <vs-button size="large" color="danger" @click="beforeDeleteDonation" icon="delete">Izbriši</vs-button>
              </vs-col>

              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
                <vs-button size="large" color="success" icon="save" @click="saveDonation()">Shrani</vs-button>
              </vs-col>
            </div>
          </div>
        </div>

        </div>
      </div>

  </div>
</template>

<script>
import Vue from 'vue';
import PrivateDonationsTable from "../../components/Sopotniki/donations/PrivateDonationsTable";
import {slSI} from "vuejs-datepicker/dist/locale";
import sir from "@/assets/images/portrait/sir.svg";
import madam from "@/assets/images/portrait/madam.svg";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";


export default {
  name: 'PrivateDonationEdit',

  components: {
    'v-select': vSelect,
    Datepicker,
    PrivateDonationsTable
  },

  data() {
    return {
      unitsData: null,
      unitId: this.$globalFunctions.getCurrentUnit().id,
      donationData: {
        donationDate: new Date(),
        donorName: null,
        donorAddress: null,
        donationAmount: null,
        donationReference: null,
      },
      slSI: slSI,
    }
  },

  computed: {},

  mounted() {
    const _this = this;

    _this.$vs.loading();

    let p1 = this.getUnits();

    Promise.all([p1]).then(() => {
      if (_this.$route.params.donationId) {
        _this.getDonation();
      }
      _this.$vs.loading.close();
    });

  },

  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    next();
  },

  methods: {
    onUnitChange() {
      const _this = this;

      _this.getDonations(_this.unitId);
    },

    async getUnits() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'units')
        .then((res) => {
          _this.unitsData = _.filter(res.data.data, {disabled: 0});
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });

    },

    async getDonation() {
      const _this = this;

      Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'finances/' + _this.$route.params.donationId)
        .then((res) => {
          _this.donationData = res.data.data;
          _this.unitId = res.data.data.unit_id;
          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    customDateFormatter(date) {
      return this.moment(date).format('DD. MM. YYYY');
    },

    beforeDeleteDonation() {
      const _this = this;

      Vue.$confirm({
        title: 'Ste prepričani, da želite izbrisati donacijo zasebnika ' + _this.donationData.donorName + ' ?',
        message: 'Po potrditvi bo donacija trajno izbrisana.',
        button: {
          yes: 'Izbriši',
          no: 'Prekliči'
        },
        callback: confirm => {
          console.log("confirm", confirm);
          if(confirm) {
            _this.saveDonation(true); // save donation and set DELETED flag
          }
        }
      });

    },

    saveDonation(deleted) {
      const _this = this;

      _this.$vs.loading();

      let donationData = {
        donationAmount: Number(_this.donationData.donationAmount.replace(',','.')),
        donationDate: _this.moment(_this.donationData.donationDate).valueOf(),
        donationId: _this.donationData.donationId ? _this.donationData.donationId : -1,
        donationReference: _this.$globalFunctions.isAdmin() ? _this.donationData.donationReference : 'G',
        donationUnit: "",
        donorAddress: _this.donationData.donorAddress,
        donorName: _this.donationData.donorName,
        id: _this.donationData.id ? _this.donationData.id : null,
        unit_id: _this.unitId,
      };

      if(deleted) {
        donationData.deleted = true;
      }

      Vue.prototype.$http.post(Vue.prototype.$config.api.productionOld + 'finances', donationData)
        .then((res) => {
          _this.$vs.loading.close();

          if(!_this.donationData.donationId) {
            Vue.$confirm({
              title: 'Donacija je uspešno shranjena. Bi želeli vnesti zapis o tej donaciji tudi v tabelo \"Gotovina\"?',
              message: 'Podatki se bodo prenesli samodejno, potrebna pa bo vaša potrditev novega zapisa.',
              button: {
                yes: 'Vnesi zapis',
                no: 'Prekliči'
              },
              callback: confirm => {
                console.log("confirm", confirm);
                if(confirm) {
                  _this.$router.push({name: 'FinancialReportEdit', params: {donationData: donationData }});
                }
              }
            });
            return;
          }

          _this.$router.push({name: 'PrivateDonations'});
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },
  }
}
</script>

